<template>
 <div class="dashboard-container">
     <div class="app-container" style="margin-bottom: 20px;font-size:20px;font-weight:800;text-align:center" >
         数据概览
     </div>
      <div class="app-container" style="margin-bottom: 20px;font-size:16px;text-align:center;color:gray" >
         点击下方卡片跳转对应页面
     </div>
       <!-- 统计表格 -->
 <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;">
      <el-card class="minecard" @click.native="ewx"> 
        <div class="minecard_item_title">微信用户数</div>
        <div class="minecard_item_text">{{ totalData.userNum}}</div>
      </el-card>
      <el-card class="minecard"  @click.native="evip">
        <div class="minecard_item_title">会员总数</div>
        <div class="minecard_item_text">
          {{ totalData.companyNum }}
        </div>
      </el-card>
      <el-card class="minecard"  @click.native="ezx">
        <div class="minecard_item_title">资讯总数</div>
        <div class="minecard_item_text">{{ totalData.articleNum}}</div>
      </el-card>
      <el-card class="minecard"  @click.native="epdf">
        <div class="minecard_item_title">文档总数</div>
        <div class="minecard_item_text">{{ totalData.pdfNum}}</div>
      </el-card>
      <el-card class="minecard"  @click.native="esq">
        <div class="minecard_item_title">待处理申请数量</div>
        <div class="minecard_item_text">{{ totalData.waitDealApplyNum}}</div>
      </el-card>
         
</div>
 </div>
</template>

<script>
import {
getOverviewData
} from "../../request/http";
export default {
data () {
    return {
          totalData:[] ,//统计信息
    }
},
created () {
    this.getOverviewData()
},
methods: {
    //跳转微信
    ewx(){
        this.$router.push({
          path:'/rotationChart'
        })
    },
    //跳转会员
    evip(){
 this.$router.push({
          path:'/memberInformation'
        })
    },

    //跳转资讯
   ezx() {
        this.$router.push({
          path:'/aboutus'
        })
    },
    //跳转文档
    epdf() {
        this.$router.push({
          path:'/pdfClassify'
        })
    },
    //跳转申请
   esq() {
        this.$router.push({
          path:'/Membership'
        })
    },
    async getOverviewData(){
       const {data} = await getOverviewData()
       this.totalData = data
      
     },
}
}
</script>

<style>
.minecard {
  box-sizing: border-box;
  width: 320px;
  height: 115px !important;
  text-align: center;
}
.minecard_item_title {
  color: #909399;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}
.minecard_item_text {
  padding-top: 10px;
  color: #303133;
  font-size: 33px;
  line-height: 1;
  font-weight: 700;
}

 .minecard .el-card__body {
  box-sizing: border-box;
  height: 100%;
}
</style>